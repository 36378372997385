import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import AdminLoader from "../components/Loader";
import AuthGuard from '../layout/AuthGuard';
import PublicRouteGuard from '../layout/PublicRouteGuard';
import PublicLayout from '../layout/PublicRoutes';
import PrivateLayout from '../layout/PrivateRoutes';

const LazyHome = lazy(() => import('../pages/Home'));
const LazyLogin = lazy(() => import("../pages/Login"));
const LazySignup = lazy(() => import("../pages/SignUp"));
const LazyForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const LazyResetPassword = lazy(() => import("../pages/ResetPassword"));
const LazySetPassword = lazy(() => import("../pages/SetPassword"));
const LazyCreateProfile = lazy(() => import("../pages/ProfileMoreInfo"));
const LazyVerificationCode = lazy(() => import("../pages/VerificationCode"));
const LazyEula = lazy(() => import("../pages/Eula"));
const LazyDashBoard = lazy(() => import("../pages/Dashboard"));
const LazyMyProfile = lazy(() => import("../pages/MyProfile"));
const LazyPlan = lazy(() => import("../pages/Plans.js"))
const LazyContactSale = lazy(() => import("../pages/ContactSale.js"))
const LazySavedPayment = lazy(() => import("../pages/SavedPayment.js"))
const LazyPurchasedPlan = lazy(() => import("../pages/PurchasedPlan.js"))
const LazySavedCards = lazy(() => import("../pages/SavedCards.js"))
const LazyChangePassword = lazy(() => import("../pages/ChangePassword.js"))
const LazyUserManagement = lazy(() => import("../pages/UserManagement.js"))
const LazyReportPreview = lazy(() => import("../pages/ReportPreview.js"))
const LazyAddDepartmentUser = lazy(() => import("../pages/AddDepartmentUser.js"))
const LazyAddMultipleUser = lazy(() => import("../pages/AddMultiple.js"))



export default function AllRoutes() {
  return (
    <Router>
      <Suspense fallback={<AdminLoader />}>
        <Routes>
          {/* Public Routes */}
          <Route element={<PublicLayout />}>
            <Route path="/" element={<LazyHome />} />
            <Route path="login" element={<PublicRouteGuard><LazyLogin /></PublicRouteGuard>} />
            <Route path="signup" element={<PublicRouteGuard><LazySignup /></PublicRouteGuard>} />
            <Route path="forgot-password" element={<PublicRouteGuard><LazyForgotPassword /></PublicRouteGuard>} />
            <Route path="reset-password" element={<PublicRouteGuard><LazyResetPassword /></PublicRouteGuard>} />
            <Route path="set-password" element={<PublicRouteGuard><LazySetPassword /></PublicRouteGuard>} />
            <Route path="verification-code" element={<PublicRouteGuard><LazyVerificationCode /></PublicRouteGuard>} />
            <Route path="create-profile" element={<LazyCreateProfile />} />
            <Route path="eula" element={<LazyEula />} />
          </Route>

          {/* Private Routes */}
          <Route element={<AuthGuard><PrivateLayout /></AuthGuard>}>
            <Route path="dashboard" element={<LazyDashBoard />} />
            <Route path="plans" element={<LazyPlan />} />
            <Route path="contact-sale" element={<LazyContactSale />} />
            <Route path="checkout" element={<LazySavedPayment />} />
            <Route path="purchased-plan" element={<LazyPurchasedPlan />} />
            <Route path="cards" element={<LazySavedCards />} />
            <Route path="my-profile" element={<LazyMyProfile />} />
            <Route path="change-password" element={<LazyChangePassword />} />
            <Route path="user-management" element={<LazyUserManagement />} />
            <Route path="report-preview" element={<LazyReportPreview />} />
            <Route path="add_department_user" element={<LazyAddDepartmentUser />} />
            <Route path="add_multiple" element={<LazyAddMultipleUser />} />
          </Route>

          {/* Redirect any other route to login if not authenticated */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
